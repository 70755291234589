import Repository from 'Repository';

export class DataIntegrationRepository extends Repository {

    constructor() {
        super();
        this.resource = 'integration';
    }

}

const IntegrationRepository = new DataIntegrationRepository();

export default IntegrationRepository;
